/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';
import { GatsbyBrowser } from 'gatsby';
import React from "react";

const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  if (location.hash) {
    // Scroll to the target element with the specified hash
    setTimeout(() => {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
    return false; // Prevent Gatsby's default scroll behavior
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
      // window.scrollTo(...(currentPosition || [0, 0]));
    }, 0);
  }

  return false;
};

export { shouldUpdateScroll };
